<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Icon } from '@iconify/vue'
import { onClickOutside } from '@vueuse/core'
import { getActivePinia } from 'pinia'
import { useToast } from 'vue-toastification'
import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'
import { useAuthStore } from '@/store/auth'
import Api from '@/utils/api'
import useAppData from '@/composables/useAppData'
import ProductsModal from '@/components/molecules/ProductsModal.vue'

const authStore = useAuthStore()
const globalStore = useGlobalStore()
const toast = useToast()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const { appId, logoSquare } = useAppData()

const showProfileMenu = ref(false)
const focusTopic = ref(null)
const menu = ref('menu')
const profileBar = ref('profileBar')

const isPremium = computed(() => userStore.user?.tier > 0)
const pageTitle = computed(() => route.meta.title ?? '')

function toggleMenu() {
  showProfileMenu.value = !showProfileMenu.value
}

async function onLogout() {
  try {
    globalStore.$patch({ isLoading: true })

    const params = {
      email: authStore.user.email,
    }

    await Api.post(`${process.env.VUE_APP_API_URL}/multi-tenant/logout`, params, {
      headers: {
        Domain: appId.value,
      },
    })

    getActivePinia()._s.forEach((store) => {
      if (store.$id !== 'app')
        store.$reset()
    })

    toast.success('Sukses Logout', { position: 'top-center' })

    globalStore.$patch({ isLoading: false })

    router.replace('/login')
  }
  catch (err) {
    console.log(err)
    toast.error('Ooppss... Ada Kesalahan')

    globalStore.$patch({ isLoading: false })
  }
}

async function onChangeTopic(event) {
  if (!event.target.value) {
    return
  }

  globalStore.$patch({ isLoading: true })

  try {
    await Api.put('/update-user-goal', {
      test_preparation_goal: event.target.value,
    })

    userStore.user.test_preparation_goal = focusTopic.value

    setTimeout(() => {
      globalStore.$patch({ isLoading: false })
      window.location.reload()
    }, 500)
  }
  catch (err) {
    focusTopic.value = userStore?.user?.test_preparation_goal
    toast.error('Ooppss... Ada Kesalahan')

    setTimeout(() => {
      globalStore.$patch({ isLoading: false })
    }, 1000)
  }
}

onClickOutside(menu, () => {
  showProfileMenu.value = false
}, {
  ignore: [profileBar],
})

watch(userStore, () => {
  focusTopic.value = userStore?.user?.test_preparation_goal
})

onMounted(() => {
  setTimeout(() => {
    focusTopic.value = userStore?.user?.test_preparation_goal
  }, 1000)
})

const productsModal = ref()
function showProducts() {
  productsModal.value.openModal()
}
</script>

<template>
  <div>
    <div class="fixed top-0 left-0 z-30 w-full md:pl-[280px]">
      <div class="jago-appbar">
        <Icon icon="mdi:view-grid" class="text-primary text-3xl md:hidden" @click="showProducts" />
        <img :src="logoSquare" class="md:hidden h-8" alt="Logo">
        <Transition name="scroll-y-reverse-transition" mode="out-in">
          <h6
            :key="pageTitle"
            class="hidden md:block font-semibold text-secondary"
          >
            {{ pageTitle }}
          </h6>
        </Transition>
        <div ref="profileBar" class="flex items-center cursor-pointer" @click="toggleMenu">
          <div class="mr-3 hidden md:flex flex-col items-end">
            <h6 class="text-small mb-1">
              Halo, <span class="font-medium">{{ userStore?.user?.user_name }}!</span>
            </h6>
            <div
              class="text-tiny rounded-full px-3"
              :class="isPremium ? 'bg-accent-lighten text-accent' : 'bg-warning-lighten text-warning'"
            >
              {{ isPremium ? 'Premium' : 'Free User' }}
            </div>
          </div>
          <img
            :src="`https://ui-avatars.com/api/?name=${userStore?.user?.user_name}&background=1F2855&color=fff`"
            class="h-8 mx-auto rounded-full" alt="Logo"
          >
        </div>

        <Transition
          name="slide-y-transition"
        >
          <div
            v-if="showProfileMenu"
            ref="menu"
            class="absolute top-[48px] right-0 bg-white rounded-lg shadow-lg border border-black-400 min-w-[200px] max-w-[60vw]"
          >
            <!-- <div class="px-3 py-2">
              <span class="text-tiny text-primary">
                Fokus Kamu
              </span>
              <select
                id="focus_test"
                v-model="focusTopic"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
                @change="onChangeTopic"
              >
                <option value="BUMN">
                  BUMN
                </option>
                <option value="CPNS">
                  CPNS
                </option>
                <option value="PPPK">
                  PPPK
                </option>
              </select>
            </div>
            <div
              class="flex text-primary menu-item"
              @click="() => { router.push('/user-detail') }"
            >
              <span class="text-caption">Ubah Profil</span>
              <Icon icon="ri:user-settings-fill" class="text-h6" />
            </div>
            <a href="https://jagotes.id" target="_blank">
              <div class="flex text-secondary menu-item">
                <span class="text-caption">Tentang Jagotes</span>
                <Icon icon="ri:external-link-line" class="text-h6" />
              </div>
            </a>
            <div class="h-[1px] w-full bg-black-400" /> -->
            <div class="md:hidden flex flex-col items-start p-3 gap-1">
              <h6 class="text-sm mb-1 max-w-[50vw] truncate">
                Halo, <span class="font-medium">{{ userStore?.user?.user_name }}!</span>
              </h6>
              <div
                class="text-tiny rounded-full px-3"
                :class="isPremium ? 'bg-accent-lighten text-accent' : 'bg-warning-lighten text-warning'"
              >
                {{ isPremium ? 'Premium' : 'Free User' }}
              </div>
            </div>

            <hr>

            <div class="flex text-danger menu-item" @click="onLogout">
              <span class="text-caption">Logout</span>
              <Icon icon="ri:logout-box-r-line" class="text-h6" />
            </div>
          </div>
        </Transition>
      </div>
    </div>

    <ProductsModal ref="productsModal" />
  </div>
</template>

<style lang="postcss">
.jago-appbar {
  @apply bg-white px-4 py-3 md:rounded-lg
  flex justify-between items-center md:m-6 mb-6 shadow-sm relative;
}

.menu-item {
  @apply items-center justify-between my-2 mx-1.5 p-2 rounded-md
  hover:bg-black-400 transition-all cursor-pointer;
}
</style>
