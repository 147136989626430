import { createApp } from 'vue'
import './registerServiceWorker'
import { createPinia } from 'pinia'
import './assets/styles/index.scss'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueApexCharts from 'vue3-apexcharts'
import VueMathjax from 'vue-mathjax-next'
import YoutubeIframe from '@techassi/vue-youtube-iframe'
import VueAnimXYZ from '@animxyz/vue3'
import '@animxyz/core'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import App from './App.vue'
import router from './router'

const toastOptions = { position: 'top-center', timeout: 1200, transition: 'Vue-Toastification__fade' }

createApp(App)
  .use(createPinia()
    .use(piniaPluginPersistedstate))
  .use(router)
  .use(Toast, toastOptions)
  .use(VueMathjax)
  .use(YoutubeIframe)
  .use(VueApexCharts)
  .use(VueAnimXYZ)
  .component('v-select', vSelect)
  .mount('#app')
