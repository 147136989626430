<script setup>
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import Loading from '../components/atoms/Loading.vue'
import NewSideBar from '../components/organisms/NewSideBar.vue'
import AppBar from '../components/organisms/AppBar.vue'
import BottomBar from '../components/organisms/BottomBar.vue'
import CustomerServiceButton from '@/components/molecules/CustomerServiceButton.vue'
import UltimatePopup from '@/components/molecules/UltimatePopup.vue'
import { useGlobalStore } from '@/store/global'

import bgm from '@/assets/pendalaman-topik/bgm.mp3'

const globalStore = useGlobalStore()
const route = useRoute()

const isPendalamanTopikSession = computed(() => route.path.includes('pendalaman-topik/session'))

watch(route, () => {
  const pendalamanTopikBgm = document.getElementById('pendalamanTopikBgm')

  if (isPendalamanTopikSession.value) {
    pendalamanTopikBgm?.play()
  }
  else {
    pendalamanTopikBgm?.pause()
  }
}, { immediate: true })
</script>

<template>
  <div class="overflow-x-hidden">
    <Loading />
    <NewSideBar />
    <div class="md:ml-[280px] pb-32">
      <AppBar />
      <div class="px-4 mt-20 md:px-6 md:mt-28">
        <slot />
      </div>

      <Transition name="slide-y-reverse-transition" mode="out-in">
        <BottomBar v-if="!globalStore.hideMobileNav" />
      </Transition>
    </div>

    <CustomerServiceButton />
    <UltimatePopup />

    <!-- pendalaman topik bgm audio -->
    <audio id="pendalamanTopikBgm">
      <source :src="bgm" type="audio/mpeg">
    </audio>
  </div>
</template>
